.standard-box {
    background-color: #363639;
}

.standard-container {
    background-color: #404042;
    color: #f0f0f0;
    min-height: 80vh;
    height: 100%;
    padding-top: 1%;
    padding-left: 1%;
    margin-left: 10%;
    margin-right: 10%;
}

.standard-container a {
    color: #c3dfff
}

.standard-container h1 {
    margin-bottom: 1em;
    font-size: 32pt;
}

.standard-container h2 {
    margin: 0.5em;
    font-size: 20pt;
}

.standard-container h3 {
    margin: 0.5em;
}

.standard-container p {
    font-size: 14pt;
}

.teams-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/teams.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    text-align: center;
    object-fit: contain;
}
.teams-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.volunteers-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/volunteers.jpg');
    background-size: cover;
    background-position: 50% 20%;
    /*background-attachment: fixed;*/
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    object-fit: contain;
}
.volunteers-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.partners-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/partners.jpg');
    background-position: 50% 60%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    text-align: center;
    object-fit: contain;
}
.partners-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.about-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/about.jpg');
    background-position: 50% 70%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    text-align: center;
    object-fit: contain;
}
.about-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.donate-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/donate.JPG');
    background-position: 50% 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    text-align: center;
    object-fit: contain;
}
.donate-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.iframe-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.states-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/states-bg5.jpg');
    background-size: 140%;
    background-position: 45% 5%;
    /*background-attachment: fixed;*/
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 100px;
    object-fit: contain;
}
.states-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}

.states-logo {
    height: 30vmin;
  }

.nopage-box {
    background-color: #363639;
    height: 100vh
}
.nopage-header {
    margin-left: 10%;
    margin-right: 10%;
    background-image: url('../../img/nopage.jpg');
    background-size: cover;
    background-position: 50% 65%;
    /*background-attachment: fixed;*/
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 100px;
    object-fit: contain;
}
.nopage-header p {
    font-size: 6vmax;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
