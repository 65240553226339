* {
  box-sizing: border-box;
  margin: 0;
  /* padding: 0;  when enabled this makes bullet points go away */
  font-family: 'Piedra', sans-serif;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
