.link {
    text-decoration: none;
}
.ms-button {
    background-color: #0275d8;
    text-align: center;
    margin: 10%;
    padding: 4%;
    border-radius: 2vh;
    text-decoration: none;
    display: inline;
    border: none;
    color: #fff;
    font-size: 4vh;
    cursor: pointer;
}

.hs-button {
    background-color: #df4759;
    text-align: center;
    margin: 10%;
    padding: 4%;
    border-radius: 2vh;
    text-decoration: none;
    display: inline;
    border: none;
    color: #fff;
    font-size: 4vh;
    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.row-item {
    padding: 1vh;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    border: 1px solid whitesmoke;
    border-radius: 5%;
}

@media screen and (max-width: 960px) {
    .row {
        display:flex;
        flex-direction: column;
    }
}